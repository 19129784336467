<script lang="ts">
	import { isMenuActive } from "../../utils/isMenuActive.js";
	import Link from "../Link.svelte";
	import { createEventDispatcher, getContext } from "svelte";
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";
	import arrowDown from "../../../../core/assets/icons/arrow-down.svg?raw";
	import Button from "../Button.svelte";
	import Submenu from "./Submenu.svelte";
	import { writable } from "svelte/store";
	import type { LocalizedString } from "../../../../core/schema/Locale.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";

	export let menu: MenuItem[];

	const { TENANT_LOCALE } = getGlobalSettings();
	const pageSlug = getContext<LocalizedString>(pageSlugContextKey);

	const dispatch = createEventDispatcher<{
		click: MenuItem;
	}>();

	const activeSubmenu = writable<MenuItem["id"] | null>(null);

	function toggleSubmenu(event: MouseEvent, id: string): void {
		event.preventDefault();
		if ($activeSubmenu === null) {
			activeSubmenu.set(id);
		} else {
			activeSubmenu.set(null);
		}
	}

	const menuItemLiClasses = "group/menu cursor-pointer lg:px-3 xl:px-5 w-full lg:w-fit relative";
	const menuItemLinkClasses =
		"text-gray-500 text-xs uppercase tracking-[0.125rem] lg:text-2xs xl:text-xs text-center !block lg:py-7 py-3 w-full lg:w-fit group-hover/menu:text-primary transition-colors duration-300 px-5 lg:px-0 lg:whitespace-nowrap";

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div class="mt-8 flex justify-center max-lg:overflow-y-auto max-lg:overflow-x-hidden lg:mt-0 lg:h-full lg:w-auto">
	<ul class="flex w-full flex-col items-center gap-4 lg:flex-row lg:justify-center lg:gap-0">
		{#each menu as menuItem}
			<li class={menuItemLiClasses}>
				<div class="relative flex w-full items-center justify-center gap-2">
					<Link
						noUnderline
						class="{menuItemLinkClasses} {isMenuActive(menuItem, pageSlug[TENANT_LOCALE]) ? 'text-primary' : ''}"
						href={`/${menuItem.path}`}
						target={menuItem.target}
						on:click={() => {
							dispatch("click", menuItem);
						}}
					>
						{nbspify(menuItem.name)}
					</Link>
					{#if menuItem.submenus.length > 0}
						<Button
							class="group-hover/menu:text-primary absolute bottom-0 right-0 top-0 h-full justify-center px-5 py-3 transition-colors duration-300 ease-in-out lg:static lg:h-fit lg:px-0"
							variant="transparent"
							icon={arrowDown}
							iconClass="w-4 lg:w-6"
							on:click={(event) => {
								toggleSubmenu(event, menuItem.id);
							}}
						></Button>
					{/if}
				</div>

				{#if menuItem.submenus.length > 0}
					<Submenu {activeSubmenu} {menuItem} />
				{/if}
			</li>
		{/each}
	</ul>
</div>
