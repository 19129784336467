<script lang="ts">
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let title: string;
	export let urlSlug: string;
	export let target = "_self";
</script>

<a
	href={urlSlug}
	{target}
	class="hover:text-tertiary text-2xs border-gray hidden h-full cursor-pointer items-center justify-center border-l px-4 text-center font-extrabold hover:bg-white lg:flex lg:whitespace-nowrap xl:px-6 xl:text-xs"
>
	{nbspify(title)}
</a>
