<script lang="ts">
	import "../assets/css/main.css";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { getContext, onMount } from "svelte";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey.js";
	import { titleContextKey } from "../contexts/titleContextKey.js";
	import { descriptionContextKey } from "../contexts/descriptionContextKey.js";
	import Footer from "./Footer.svelte";
	import MarketingFooter from "./MarketingFooter.svelte";
	import Header from "./Header.svelte";
	import HeadFavicons from "./HeadFavicons.svelte";
	import { menuContextKey } from "../contexts/menuContextKey.js";
	import type { MenuItem } from "../../../core/schema/MenuItem";
	import GoogleAnalytics from "./GoogleAnalytics.svelte";
	import type { Breadcrumb } from "../../../core/schema/Breadcrumb.js";
	import { breadcrumbsContextKey } from "../contexts/breadcrumbsContextKey.js";
	import Breadcrumbs from "./Breadcrumbs.svelte";
	import { appName } from "../../../core/schema/appName.js";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import quicksandRegular from "../../../core/assets/fonts/quicksand/Quicksand-Regular.woff2";
	import quicksandBold from "../../../core/assets/fonts/quicksand/Quicksand-Bold.woff2";
	import fustatRegular from "../../../core/assets/fonts/fustat/Fustat-Regular.woff2";
	import fustatBold from "../../../core/assets/fonts/fustat/Fustat-Bold.woff2";
	import fustatExtraBold from "../../../core/assets/fonts/fustat/Fustat-ExtraBold.woff2";
	import { createTitle } from "../utils/createTitle.js";
	import { keywordsContextKey } from "../contexts/keywordsContextKey.js";
	import { thumbnailImageContextKey } from "../contexts/thumbnailImageContextKey.js";
	import type { LocalizedString } from "../../../core/schema/Locale";
	import thumbnail from "../assets/images/service-equip.png";

	const { ROBOTS, WEB_URL, TENANT_LOCALE } = getGlobalSettings();

	const breadcrumbs = getContext<Breadcrumb[]>(breadcrumbsContextKey);
	const pageSlug = getContext<LocalizedString>(pageSlugContextKey);
	const title = createTitle(getContext<string | undefined>(titleContextKey));
	const description = getContext<string>(descriptionContextKey);
	const keywords = getContext<string | undefined>(keywordsContextKey);
	const menu = getContext<MenuItem[]>(menuContextKey);
	const ogImage = getContext<string | undefined>(thumbnailImageContextKey) ?? thumbnail;

	export let noPaddingTop = false;

	onMount(async () => {
		await import("../assets/js/fslightbox-pro-3.4.1/fslightbox.js");
	});

	const canonical = `${WEB_URL}/${pageSlug[TENANT_LOCALE]}`;
	$: {
		document.documentElement.style.overflow = $scrollLockStore ? "hidden" : "auto";
	}
</script>

<svelte:head>
	<meta property="og:site_name" content={appName} />
	<meta name="twitter:site" content={appName} />
	<meta name="application-name" content={appName} />
	<meta name="apple-mobile-web-app-title" content={appName} />
	<meta name="robots" content={`${ROBOTS}, ${ROBOTS === "noindex" ? "no" : ""}follow`} />
	{#each [quicksandRegular, quicksandBold, fustatRegular, fustatBold, fustatExtraBold] as font}
		<link rel="preload" as="font" type="font/woff2" href={font} crossorigin="anonymous" />
	{/each}
	<link rel="canonical" href={canonical} />

	<!-- Dynamic Open graph tags (Meta) -->
	<meta property="og:url" content={canonical} />
	<meta property="og:image" content={ogImage} />

	<!-- Dynamic Twitter Meta tags -->
	<meta name="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content={WEB_URL} />
	<meta property="twitter:url" content={canonical} />
	{#if ogImage}
		<meta name="twitter:image" content={ogImage} />
	{/if}

	<!-- Title and description -->
	<title>{title}</title>
	<meta property="og:title" content={title} />
	<meta property="og:title" content={title} />
	<meta name="twitter:title" content={title} />
	<meta name="description" content={description} />
	<meta name="og:description" content={description} />
	<meta name="twitter:description" content={description} />

	{#if keywords}
		<meta name="keywords" content={keywords} />
	{/if}
</svelte:head>

<HeadFavicons />

<GoogleAnalytics />

<div class="contents">
	<Header {menu} bottomBorderFixed={!noPaddingTop} />

	<main class="grow pb-12 lg:pb-24 xl:pb-32 {!noPaddingTop && 'pt-20 lg:pt-[4.625rem] xl:pt-[4.8125rem]'}">
		<Breadcrumbs {breadcrumbs} />

		<slot />
	</main>

	<Footer />
</div>

<MarketingFooter />
